import { Form, FormikProps } from "formik";
import { useQuery as useReactQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";

import { queryClient } from "@src/Clients/ReactQuery/reactQueryClient";
import { ButtonRow } from "@src/Components/Buttons/ButtonRow";
import { GhostButton } from "@src/Components/Buttons/Ghost";
import { PrimaryButton } from "@src/Components/Buttons/Primary";
import { ConfigFieldsForm } from "@src/Components/Forms/DynamicForms/ConfigFieldsForm";
import { ConfigField, yupConfigSchema } from "@src/Components/Forms/DynamicForms/initFormConfig";
import { FormError, FormErrorContainer } from "@src/Components/Forms/FormErrorContainer";
import { Fieldset } from "@src/Components/Input/InputGroup";
import { Title } from "@src/Components/Modal/Modal";
import { H2, H3 } from "@src/Components/Text";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useToggle } from "@src/Hooks/toggle";

import { headerNetworksKey } from "../mpnRequests";
import { fetchNetworks, SerialisedNetwork, serialiseNetworksData } from "../networks";
import { useSelectNetwork } from "../selectNetwork";
import { networkRegistrationChartName } from "./networkRegistrationChart";

const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const VerticalButtonsWrapper = styled(SuccessWrapper)`
  gap: 16px;
  button {
    width: auto;
    padding: 0px 18px;
  }
`;

export interface NetworkForm {
  fields: ConfigField[];
}

export const networkFormSchema = Yup.object<NetworkForm>().shape({
  fields: yupConfigSchema
});

interface NetworkFormInnerProps extends FormikProps<NetworkForm> {
  submitErrors?: FormError;
  goBack: () => void;
  isEdit?: boolean;
}

export function NetworkFormInner({
  isEdit = false,
  goBack,
  submitErrors,
  ...formikProps
}: NetworkFormInnerProps) {
  const { isValid, isSubmitting, values, errors, setFieldValue } = formikProps;
  return (
    <>
      <H2>{isEdit ? "Edit" : "Add a New"} Network</H2>

      <FormErrorContainer error={submitErrors}>
        <H3>Failed to {isEdit ? "Edit" : "Create New"} Network</H3>
      </FormErrorContainer>

      <Form>
        <ConfigFieldsForm
          name="fields"
          configFields={values.fields}
          errors={errors}
          isEdit={isEdit}
          setFieldValue={setFieldValue}
        />

        <Fieldset>
          <ButtonRow
            onClickBack={goBack}
            isValid={isValid}
            isSubmitting={isSubmitting}
            backText="Cancel"
            submitText={isEdit ? "Edit" : "Create"}
          />
        </Fieldset>
      </Form>
    </>
  );
}

interface SuccessModalProps {
  id: string;
  isEdit?: boolean;
}

export function SuccessModal({ id, isEdit = false }: SuccessModalProps) {
  const { selectNetwork } = useSelectNetwork();
  const orgCtx = useOrgCtx() as string;
  const navigate = useNavigate();
  const { state: fetchingNetwork, off: failFetch } = useToggle(true);

  const { data } = useReactQuery(
    [`network-poll-fetch-${id}`, orgCtx],
    () => fetchNetworks(orgCtx),
    {
      refetchOnWindowFocus: false,
      refetchInterval: (data, query) => {
        if (!data) return 1000;

        const newNetwork = (serialiseNetworksData(data) as SerialisedNetwork[]).find(n =>
          n.uid.includes(id)
        );

        if (newNetwork) {
          queryClient.refetchQueries({ queryKey: [headerNetworksKey] });
          return false;
        } else if (query.state.dataUpdateCount > 10) {
          failFetch();
          return false;
        }
        return 1000;
      },
      enabled: !isEdit && !!orgCtx
    }
  );

  const newNetwork = (serialiseNetworksData(data || []) as SerialisedNetwork[]).find(n =>
    n.uid.includes(id)
  );
  return (
    <>
      <SuccessWrapper>
        <Title>Network {isEdit ? "Edited" : "Created"} Successfully</Title>
        {isEdit ? (
          <GhostButton onClick={() => navigate(-1)}>Go back to previous page</GhostButton>
        ) : (
          <>
            {fetchingNetwork ? (
              <>
                <H3>Do you want to switch to the new network?</H3>
                <VerticalButtonsWrapper>
                  <PrimaryButton
                    disabled={!newNetwork}
                    onClick={() => {
                      if (!newNetwork) return;
                      selectNetwork(newNetwork.uid);
                    }}
                  >
                    {!!newNetwork
                      ? "Yes, take me to the new network"
                      : "Please wait until the network is available..."}
                  </PrimaryButton>
                  <GhostButton onClick={() => navigate(-1)}>Go back to previous page</GhostButton>
                </VerticalButtonsWrapper>
              </>
            ) : (
              <>
                <H3>
                  The network is being processed at the moment. It will show up on the Network
                  Selector once it´s ready.
                </H3>
                <GhostButton onClick={() => navigate(-1)}>Go back to previous page</GhostButton>
              </>
            )}
          </>
        )}
      </SuccessWrapper>
    </>
  );
}

export function serialiseNetworkForm(values: NetworkForm, chartVersion: string, orgId: string) {
  const deploymentValues = values.fields
    .filter(({ selected }) => selected)
    .reduce(
      (obj, field) => {
        switch (field.label) {
          case "name":
            obj.name = String(field.value);
          default:
            return {
              ...obj,
              config: {
                ...obj.config,
                [field.label]: String(field.value)
              }
            };
        }
      },
      { config: {}, name: "" }
    );

  return {
    name: "required-by-tmf-but-not-used",
    relatedParty: [
      {
        id: orgId,
        "@referredType": "Organization"
      }
    ],
    resourceCharacteristic: [
      {
        name: "nearbyone-override-values",
        valueType: "object",
        value: {
          Block: {
            InstanceId: "placeholder"
          },
          deployment: deploymentValues
        }
      },
      {
        name: "nearbyone-chart-name",
        valueType: "string",
        value: networkRegistrationChartName
      },
      {
        name: "nearbyone-chart-version",
        valueType: "string",
        value: chartVersion
      }
    ]
  };
}
