import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { Clickable } from "@src/Components/Buttons/Clickable";
import { boxShadow } from "@src/Components/Table/Table";
import { headerMenusZIndex } from "@src/Components/zIndexValues";

export const linkStyles = css`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
  text-decoration: none;
`;

export const MenuA = styled.a`
  ${linkStyles}
`;

export const MenuLink = styled(Link)`
  ${linkStyles}
`;

export const UserMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid ${({ theme }) => theme.border};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 16px;
  box-sizing: border-box;
  cursor: default;
  z-index: ${headerMenusZIndex};
  ${boxShadow}
`;

export const HeaderLabel = styled.span`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #4a4a4a;
  padding: 8px;
  line-height: 1;
`;

export const Separator = styled.hr`
  color: ${({ theme }) => theme.border};
  width: 100%;
  margin: 14px 0;
`;

export const HeaderItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const clickableHeaderStyles = css`
  border-radius: 3px;
  &:hover {
    color: ${({ theme }) => theme.primary};
    fill: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundLight};
    & span {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const HeaderClickable = styled(Clickable)`
  padding: 0px 6px;
  height: 100%;
  ${clickableHeaderStyles}
`;

export const HeaderLink = styled(NavLink)`
  ${linkStyles}
  display: flex;
  font-weight: 500;
  padding: 0 10px;
  align-items: center;
  color: ${({ theme }) => theme.text};
  & span {
    padding-right: 0px;
  }
  ${clickableHeaderStyles}
`;
