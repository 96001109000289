import { Navigate } from "react-router-dom";

import { useMPNDashboard } from "@src/Hooks/Permissions/mpnDashboard";
import { useServicesDashboard } from "@src/Hooks/servicesDashboard";

import { useCanAccessGlobalSettingsDashboard } from "./Hooks/Permissions/globalSettingsDashboard";
import { useInfrastructureDashboard } from "./Hooks/Permissions/infrastructureDashboard";
import { useLandingPage } from "./Hooks/Permissions/landingPage";
import { useMECDashboard } from "./Hooks/Permissions/mecDashboard";
import { useCanAccessTenantSettingsDashboard } from "./Hooks/Permissions/tenantSettingsDashboard";

export function DefaultPageRedirect() {
  const { allowed: allowServices } = useServicesDashboard();
  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: allowMEC } = useMECDashboard();
  const { allowed: showLanding } = useLandingPage();
  const { allowed: allowInfra } = useInfrastructureDashboard();
  const { allowed: allowSettings } = useCanAccessTenantSettingsDashboard();
  const { allowed: allowGlobalSettings } = useCanAccessGlobalSettingsDashboard();

  if (allowMPN == null || allowServices == null || allowMEC == null || allowMEC == null) {
    return null;
  }

  const landingPage = { url: "home", hasPermission: showLanding };
  const servicesDashboard = { url: "services/overview", hasPermission: allowServices };
  const mpnDashboard = { url: "mpn", hasPermission: allowMPN };
  const mecDashboard = { url: "mec", hasPermission: allowMPN };
  const infraDashboard = { url: "infrastructure", hasPermission: allowInfra };
  const tenantSettingsDashboard = { url: "settings", hasPermission: allowSettings };
  const globalSettingsDashboard = { url: "global-settings", hasPermission: allowGlobalSettings };

  const dashboards = [
    landingPage,
    servicesDashboard,
    mpnDashboard,
    mecDashboard,
    infraDashboard,
    tenantSettingsDashboard,
    globalSettingsDashboard
  ];

  const redirectPath = dashboards.find(d => d.hasPermission)?.url || "no-dashboard";

  return <Navigate to={redirectPath} />;
}
