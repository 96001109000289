import { MutableRefObject, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { dashboardButtonStyles } from "./Components/Buttons/dashboardButtonStyles";
import { DisableableNavLink } from "./Components/Navigation/DisableableNavLink";
import { HeaderClickable, HeaderItem, HeaderLabel, UserMenu } from "./Header/HeaderStyles";
import { useCurrentDashboard } from "./Hooks/Dashboard/currentDashboard";
import { DashboardHeaderIcon, DashboardMenuIcon } from "./Hooks/Dashboard/dashboardIcons";
import {
  cloudManager,
  globalSettings,
  infrastructureDashboard,
  mpnDashboard,
  servicesDashboard,
  tenantSettings
} from "./Hooks/Dashboard/dashboards";
import { useDefaultOrg } from "./Hooks/defaultOrg";
import { useCanAccessGlobalSettingsDashboard } from "./Hooks/Permissions/globalSettingsDashboard";
import { useInfrastructureDashboard } from "./Hooks/Permissions/infrastructureDashboard";
import { useMECDashboard } from "./Hooks/Permissions/mecDashboard";
import { useMPNDashboard } from "./Hooks/Permissions/mpnDashboard";
import { useCanAccessTenantSettingsDashboard } from "./Hooks/Permissions/tenantSettingsDashboard";
import { useServicesDashboard } from "./Hooks/servicesDashboard";
import { useToggle } from "./Hooks/toggle";

const DashboardDisplayName = styled(HeaderLabel)`
  white-space: nowrap;
`;
const DashboardsMenuWrapper = styled(UserMenu)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 4px;
  justify-items: center;
`;

const DashboardIconLink = styled(DisableableNavLink)<{ $active: boolean }>`
  ${dashboardButtonStyles};
  width: 80px;
  text-decoration: none;
`;

export function DashboardsMenu() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const {
    state: isOpenDashboards,
    toggle: toggleMenuDashboards,
    off: closeMenuDashboards
  } = useToggle();

  const dashboardsMenu = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(dashboardsMenu, closeMenuDashboards);

  const {
    isServices,
    isMPN,
    isMEC,
    isTenantSettings,
    isGlobalSettings,
    isInfrastructure,
    dashboard
  } = useCurrentDashboard();

  const { allowed: allowMPN } = useMPNDashboard();
  const { allowed: showMEC } = useMECDashboard();
  const { allowed: allowInfra } = useInfrastructureDashboard();
  const { allowed: allowServices } = useServicesDashboard();
  const { allowed: accessTenantSettings } = useCanAccessTenantSettingsDashboard();
  const { allowed: accessGlobalSettings } = useCanAccessGlobalSettingsDashboard();

  const org = orgCtx || defaultOrg;

  const disableServicesDashboard = !allowServices || !org;
  const disableMPNDashboard = !allowMPN || !org;

  return (
    <HeaderItem ref={dashboardsMenu}>
      <HeaderClickable onClick={toggleMenuDashboards} title="dashboards menu">
        <DashboardHeaderIcon dashboard={dashboard.name} />
        <DashboardDisplayName>{dashboard.displayName}</DashboardDisplayName>
        {isOpenDashboards ? (
          <UpArrow height={14} width={14} />
        ) : (
          <DownArrow height={14} width={14} />
        )}
      </HeaderClickable>
      {isOpenDashboards && (
        <DashboardsMenuWrapper onClick={closeMenuDashboards}>
          <DashboardIconLink
            to={servicesDashboard.route(org)}
            $active={isServices}
            disabled={disableServicesDashboard}
          >
            <DashboardMenuIcon dashboard={servicesDashboard.name} />
            <span>{servicesDashboard.displayName}</span>
          </DashboardIconLink>
          {allowInfra && (
            <DashboardIconLink to={infrastructureDashboard.route(org)} $active={isInfrastructure}>
              <DashboardMenuIcon dashboard={infrastructureDashboard.name} />
              <span>{infrastructureDashboard.displayName}</span>
            </DashboardIconLink>
          )}
          <DashboardIconLink
            to={mpnDashboard.route(org)}
            $active={isMPN}
            disabled={disableMPNDashboard}
          >
            <DashboardMenuIcon dashboard={mpnDashboard.name} />
            <span>{mpnDashboard.displayName}</span>
          </DashboardIconLink>
          {showMEC && (
            <DashboardIconLink to={cloudManager.route(org)} $active={isMEC}>
              <DashboardMenuIcon dashboard={cloudManager.name} />
              <span>{cloudManager.displayName}</span>
            </DashboardIconLink>
          )}
          {accessTenantSettings && (
            <DashboardIconLink to={tenantSettings.route(org)} $active={isTenantSettings}>
              <DashboardMenuIcon dashboard={tenantSettings.name} />
              <span>{tenantSettings.displayName}</span>
            </DashboardIconLink>
          )}
          {accessGlobalSettings && (
            <DashboardIconLink to={globalSettings.route(org)} $active={isGlobalSettings}>
              <DashboardMenuIcon dashboard={globalSettings.name} />
              <span>{globalSettings.displayName}</span>
            </DashboardIconLink>
          )}
        </DashboardsMenuWrapper>
      )}
    </HeaderItem>
  );
}
