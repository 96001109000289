import styled, { css } from "styled-components";

import { DisableableNavLink } from "./DisableableNavLink";

const linkStyles = css`
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin: 20px auto;
  padding: 10px 0;
  color: white;
`;

const activeStyles = css`
  color: white;
  circle {
    fill: white;
  }
  path {
    fill: #333167;
  }
`;

export const IconLink = styled(DisableableNavLink)`
  ${linkStyles}

  &.active {
    ${activeStyles}
  }
`;

export const ExternalIconLink = styled.a`
  ${linkStyles}

  &:not([href]) {
    cursor: not-allowed;
  }
`;
