import { useEffect, useRef } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { H1, H3 } from "@src/Components/Text";
import { useCurrentDashboard } from "@src/Hooks/Dashboard/currentDashboard";

export const FallbackContainer = styled.div`
  padding: 80px;
`;

export function DashboardErrorFallback() {
  const { pathname } = useLocation();
  const { dashboard } = useCurrentDashboard();

  const pathRef = useRef(pathname);

  const { resetBoundary } = useErrorBoundary();

  useEffect(() => {
    if (pathRef.current === pathname) return;
    pathRef.current = pathname;
    resetBoundary();
  }, [pathname, resetBoundary]);

  return (
    <FallbackContainer>
      <H1>Something went wrong while loading up the {dashboard.displayName} Dashboard.</H1>
      <H3>
        Contact you System Admin to check what can be done about this issue. You might still have
        access to other dashboards inside the App.
      </H3>
    </FallbackContainer>
  );
}
