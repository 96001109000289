import { GlobalObjectPolicyID } from "@src/Generated/permissions/globalObjectPolicyID";
import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanAccessGlobalSettingsDashboard() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: GlobalObjectPolicyID.GlobalSettingsDashboard,
    relation: Permit.Read
  });
}
