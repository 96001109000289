import { css } from "styled-components";

export const dashboardButtonStyles = css<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;
  position: relative;
  background-color: ${({ theme, $active }) => $active && theme.backgroundLight};
  color: ${({ theme, $active }) => ($active ? theme.primary : theme.text)};

  & svg {
    fill: ${({ theme, $active }) => $active && theme.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundLight};
    & svg {
      fill: ${({ theme }) => theme.primary};
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.grey};
    background-color: white;
    border: 2px solid ${({ theme }) => theme.grey};
    & svg {
      fill: ${({ theme }) => theme.grey};
    }
  }
`;
