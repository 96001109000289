import CustomizationIcon from "@img/customizations.svg";
import GroupsIcon from "@img/groups.svg";
import OrgIcon from "@img/org.svg";
import UserIcon from "@img/user-filled.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { globalSettings } from "@src/Hooks/Dashboard/dashboards";
import { useCanReadEnvBranding } from "@src/Hooks/Permissions/envBranding";

export function GlobalSettingsNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = globalSettings.route(orgCtx);

  const { allowed: canReadCustomizations } = useCanReadEnvBranding();

  if (!orgCtx) return <OrgLessNavbar />;
  return (
    <NavIcons>
      {canReadCustomizations && (
        <NavItem
          linkTo={rootPath + "/customizations"}
          label="Customization"
          icon={CustomizationIcon}
        />
      )}
      <NavItem linkTo={rootPath + "/users"} label="Users" icon={UserIcon} />
      <NavItem linkTo={rootPath + "/groups"} label="Groups" icon={GroupsIcon} />
      <NavItem linkTo={rootPath + "/organizations"} label="Organizations" icon={OrgIcon} />
    </NavIcons>
  );
}

function OrgLessNavbar() {
  return (
    <NavIcons>
      <NavItem linkTo={"/app/global-settings/organizations"} label="Organizations" icon={OrgIcon} />
    </NavIcons>
  );
}
