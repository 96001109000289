import styled, { css } from "styled-components";

import MECDashboard from "@img/cloud-manager.svg";
import Dashboards from "@img/dashboards.svg";
import GlobalSettings from "@img/global-settings.svg";
import Infrastructure from "@img/network.svg";
import ServicesDashboard from "@img/services.svg";
import MPNDashboard from "@img/sim-card.svg";
import TenantSettings from "@img/tenant-settings.svg";

import { Dashboard } from "./currentDashboard";

const menuIconStyles = css`
  height: 50px;
`;
const headerIconStyles = css`
  height: 22px;
`;

const ServicesMenuIcon = styled(ServicesDashboard)`
  ${menuIconStyles}
`;
const ServicesHeaderIcon = styled(ServicesDashboard)`
  ${headerIconStyles}
`;

const MPNMenuIcon = styled(MPNDashboard)`
  ${menuIconStyles}
  width: 42px;
`;
const MPNHeaderIcon = styled(MPNDashboard)`
  ${headerIconStyles}
`;

const CloudManagerMenuIcon = styled(MECDashboard)`
  ${menuIconStyles}
`;
const CloudManagerHeaderIcon = styled(MECDashboard)`
  ${headerIconStyles}
`;

const InfrastructureMenuIcon = styled(Infrastructure)`
  ${menuIconStyles}
`;
const InfrastructureHeaderIcon = styled(Infrastructure)`
  ${headerIconStyles}
`;

const TenantSettingsMenuIcon = styled(TenantSettings)`
  ${menuIconStyles}
`;
const TenantSettingsHeaderIcon = styled(TenantSettings)`
  ${headerIconStyles}
`;

const GlobalSettingsMenuIcon = styled(GlobalSettings)`
  ${menuIconStyles}
`;
const GlobalSettingsHeaderIcon = styled(GlobalSettings)`
  ${headerIconStyles}
`;

const LandingHeaderIcon = styled(Dashboards)`
  ${headerIconStyles}
`;

type DashboardIconProps = {
  dashboard: Dashboard;
};

export function DashboardMenuIcon({ dashboard }: DashboardIconProps) {
  switch (dashboard) {
    case "services":
      return <ServicesMenuIcon />;
    case "mpn":
      return <MPNMenuIcon />;
    case "mec":
      return <CloudManagerMenuIcon />;
    case "infrastructure":
      return <InfrastructureMenuIcon />;
    case "settings":
      return <TenantSettingsMenuIcon />;
    case "global-settings":
      return <GlobalSettingsMenuIcon />;
    case "home":
      return <LandingHeaderIcon />;
    default:
      return <></>;
  }
}

export function DashboardHeaderIcon({ dashboard }: DashboardIconProps) {
  switch (dashboard) {
    case "services":
      return <ServicesHeaderIcon />;
    case "mpn":
      return <MPNHeaderIcon />;
    case "mec":
      return <CloudManagerHeaderIcon />;
    case "infrastructure":
      return <InfrastructureHeaderIcon />;
    case "settings":
      return <TenantSettingsHeaderIcon />;
    case "global-settings":
      return <GlobalSettingsHeaderIcon />;
    default:
      return <></>;
  }
}
