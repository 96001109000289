import { GlobalSettingsNavbar } from "./AdminDashboard/GlobalSettingsNavbar";
import { TenantSettingsNavbar } from "./AdminDashboard/TenantSettingsNavbar";
import { useCurrentDashboard } from "./Hooks/Dashboard/currentDashboard";
import { InfrastructureNavbar } from "./InfrastructureDashboard/InfrastructureNavbar";
import { MECNavbar } from "./MECDashboard/MECNavBar";
import { MPNNavbar } from "./MPNDashboard/MPNNavbar";
import { ServicesNavbar } from "./ServicesDashboard/ServicesNavbar";

export function Navbar() {
  const { dashboard } = useCurrentDashboard();

  switch (dashboard.name) {
    case "services":
      return <ServicesNavbar />;
    case "mpn":
      return <MPNNavbar />;
    case "mec":
      return <MECNavbar />;
    case "infrastructure":
      return <InfrastructureNavbar />;
    case "settings":
      return <TenantSettingsNavbar />;
    case "global-settings":
      return <GlobalSettingsNavbar />;
    default:
      return <></>;
  }
}
