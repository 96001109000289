import { MutableRefObject, useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "usehooks-ts";

import DownArrow from "@img/arrow_light_down.svg";
import UpArrow from "@img/arrow_light_up.svg";
import About from "@img/info-circle.svg";
import User from "@img/user.svg";
import { TextButton } from "@src/Components/Buttons/Text";
import { useCurrentDashboard } from "@src/Hooks/Dashboard/currentDashboard";
import { useToggle } from "@src/Hooks/toggle";

import { useLogoutHandler } from "./Auth/Logout";
import { DashboardsMenu } from "./DashboardsMenu";
import { HeaderOrgSelector } from "./Header/HeaderOrgSelector";
import {
  HeaderClickable,
  HeaderItem,
  HeaderLabel,
  HeaderLink,
  MenuLink,
  Separator,
  UserMenu
} from "./Header/HeaderStyles";
import { QuickNavMenu } from "./Header/QuickNavMenu";
import { useOrgCtx } from "./Hooks/Context/orgCtx";
import { useCanAccessComponentVersionsPage } from "./Hooks/Permissions/componentVersions";
import { NetworkSelector } from "./MPNDashboard/NetworkSelector";
import { QuickNavMenuState } from "./quickNavMenuState";
import { useSession } from "./Session";
import { ClientLogo } from "./UserSettings/ClientLogo";

const headerPadding = 32;

export const HeaderWrapper = styled.header`
  grid-area: header;
  height: 50px;
  background: white;
  display: flex;
  gap: 10px;
  align-items: stretch;
  justify-content: flex-end;
  padding: 4px ${headerPadding}px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
`;

export const SeparatorUser = styled(Separator)`
  &:first-of-type {
    margin-top: auto;
  }
`;

export const MenuButton = styled(TextButton)`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #666666;
`;

const AboutIcon = styled(About)`
  width: 18px;
`;

export function Header(quickNavProps: QuickNavMenuState) {
  const { state: isOpenUser, toggle: toggleMenuUser, off: closeMenuUser } = useToggle();

  const orgCtx = useOrgCtx();
  const { session } = useSession();
  const userName = session?.identity?.traits?.name;

  const logout = useLogoutHandler();

  const userMenu = useRef() as MutableRefObject<HTMLDivElement>;
  useOnClickOutside(userMenu, closeMenuUser);

  const { isMPN, dashboard } = useCurrentDashboard();

  const { allowed: canAccessCompVer } = useCanAccessComponentVersionsPage();
  return (
    <HeaderWrapper>
      <QuickNavMenu {...quickNavProps} />

      {dashboard !== null && <HeaderOrgSelector />}

      {isMPN && <NetworkSelector />}

      <ClientLogo />

      {canAccessCompVer && (
        <HeaderLink to={`/app/${orgCtx}/about`}>
          <AboutIcon />
          <HeaderLabel>About</HeaderLabel>
        </HeaderLink>
      )}

      <DashboardsMenu />

      <HeaderItem ref={userMenu}>
        <HeaderClickable onClick={toggleMenuUser}>
          <User height={30} width={30} />
          <HeaderLabel>{userName}</HeaderLabel>
          {isOpenUser ? <UpArrow height={14} width={14} /> : <DownArrow height={14} width={14} />}
        </HeaderClickable>
        {isOpenUser ? (
          <UserMenu>
            <User height={160} width={160} />
            <SeparatorUser />
            <MenuLink to={`/app/${orgCtx}/user/settings`}>Profile settings</MenuLink>
            <SeparatorUser />
            <MenuButton onClick={logout}>Sign out</MenuButton>
          </UserMenu>
        ) : null}
      </HeaderItem>
    </HeaderWrapper>
  );
}
