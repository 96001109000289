import { GlobalObjectPolicyID } from "@src/Generated/permissions/globalObjectPolicyID";
import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useCanAccessTenantSettingsDashboard() {
  return usePermissionCheck({
    objectType: Namespace.ObjectPolicy,
    objectId: GlobalObjectPolicyID.OrgSettings,
    relation: Permit.Read
  });
}
