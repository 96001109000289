import CompletionsIcon from "@img/completions.svg";
import CustomizationIcon from "@img/customizations.svg";
import MapIcon from "@img/map.svg";
import ReportIcon from "@img/reports.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { tenantSettings } from "@src/Hooks/Dashboard/dashboards";
import { useCanReadOrgSettings } from "@src/Hooks/Permissions/orgSettings";
import { useCanAccessReportsPage } from "@src/Hooks/Permissions/reportsPage";

export function TenantSettingsNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = tenantSettings.route(orgCtx);

  const { allowed: canReadCustomizations } = useCanReadOrgSettings(orgCtx);
  const { allowed: canAccessReports } = useCanAccessReportsPage();

  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "/map-settings"} label="Map Settings" icon={MapIcon} />
      <NavItem linkTo={rootPath + "/completions"} label="Completions" icon={CompletionsIcon} />
      {canReadCustomizations && (
        <NavItem
          linkTo={rootPath + "/customizations"}
          label="Customization"
          icon={CustomizationIcon}
        />
      )}
      {canAccessReports && (
        <NavItem linkTo={rootPath + "/reports"} label="Reports" icon={ReportIcon} />
      )}
    </NavIcons>
  );
}
