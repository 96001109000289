import { GlobalObjectPolicyID } from "@src/Generated/permissions/globalObjectPolicyID";
import { Namespace } from "@src/Generated/permissions/namespace";
import { Permit } from "@src/Generated/permissions/permit";

import { usePermissionCheck } from "../permissionsCheck";

export function useInfrastructureDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: Permit.Read,
    objectType: Namespace.ObjectPolicy,
    objectId: GlobalObjectPolicyID.InfrastructureDashboard
  });

  return { allowed, loading };
}
