import { useLocation } from "react-router-dom";

import InventoryIcon from "@img/clipboard-list-solid.svg";
import FederationIcon from "@img/federation.svg";
import OverviewIcon from "@img/map.svg";
import ProvisionIcon from "@img/provision.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { infrastructureDashboard } from "@src/Hooks/Dashboard/dashboards";
import { useCanViewFederation } from "@src/Hooks/Permissions/federation";
import { useCanReadProvision } from "@src/Hooks/Permissions/provision";

export function InfrastructureNavbar() {
  const orgCtx = useOrgCtx();
  const { pathname } = useLocation();

  const siteCtx = extractSiteCtx(pathname);

  const rootPath = infrastructureDashboard.route(orgCtx, siteCtx);

  const { allowed: canViewFederation } = useCanViewFederation(orgCtx);
  const { allowed: canViewProvision } = useCanReadProvision();

  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "/overview"} label="Overview" icon={OverviewIcon} />
      <NavItem linkTo={rootPath + "/inventory"} label="Inventory" icon={InventoryIcon} />
      {canViewProvision && (
        <NavItem linkTo={rootPath + "/provision"} label="Provisioning Jobs" icon={ProvisionIcon} />
      )}
      <NavItem linkTo={rootPath + "/services"} label="Services" icon={ServicesIcon} />
      {canViewFederation && (
        <NavItem
          disabled={!canViewFederation}
          linkTo={rootPath + "/federation"}
          label="Federations"
          icon={FederationIcon}
        />
      )}
    </NavIcons>
  );
}

function extractSiteCtx(path: string) {
  const matchPath = /site\/(?<siteCtx>[^\/]+)/;

  const match = path.match(matchPath);

  return match?.groups?.siteCtx;
}
