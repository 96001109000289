import { Dashboard } from "./currentDashboard";

export interface DashboardObject {
  name: Dashboard;
  displayName: string;
  route: (org?: string, subRoute?: string) => string;
}

export const servicesDashboard = Object.freeze({
  name: "services",
  displayName: "Services Dashboard",
  route: org => (org ? `/app/${org}/services` : "/")
} as DashboardObject);

export const mpnDashboard = Object.freeze({
  name: "mpn",
  displayName: "LTE/5G Networks",
  route: org => (org ? `/app/${org}/mpn` : "/")
} as DashboardObject);

export const cloudManager = Object.freeze({
  name: "mec",
  displayName: "Cloud Manager",
  route: org => (org ? `/app/${org}/mec` : "/")
} as DashboardObject);

const infrastructureRoute = (org?: string, site?: string) => {
  return org ? `/app/${org}/infrastructure${site ? `/site/${site}` : ""}` : "/";
};

export const infrastructureDashboard = Object.freeze({
  name: "infrastructure",
  displayName: "Infrastructure Manager",
  route: infrastructureRoute
} as DashboardObject);

export const tenantSettings = Object.freeze({
  name: "settings",
  displayName: "Tenant Settings",
  route: org => (org ? `/app/${org}/settings` : "/")
} as DashboardObject);

export const globalSettings = Object.freeze({
  name: "global-settings",
  displayName: "Global Settings",
  route: org => (org ? `/app/${org}/global-settings` : "/")
} as DashboardObject);

export const landingPage = Object.freeze({
  name: "home",
  displayName: "Landing Page",
  route: org => (org ? `/app/${org}/home` : "/")
} as DashboardObject);

export const noDashboard = Object.freeze({
  name: "no-dashboard",
  displayName: "Dashboards",
  route: _ => `/`
} as DashboardObject);
