import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

interface LogsQueryParams {
  from?: string | number;
  to?: string | number;
  alias?: string;
  size?: number;
  types?: string[] | null;
}

export function useLogNavigation() {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = useCallback(
    (params: LogsQueryParams, removeAlias = false) => {
      const search = new URLSearchParams(
        [
          ["from", params.from?.toString() || searchParams.get("from")],
          ["to", params.to?.toString() || searchParams.get("to")],
          ["alias", removeAlias ? "" : params.alias || searchParams.get("alias")],
          ["pageSize", params.size?.toString() || searchParams.get("pageSize")]
        ].filter(s => s[1])
      );

      (params.types || searchParams.getAll("type")).forEach(t => search.append("type", t));
      setSearchParams(search);
    },
    [searchParams, setSearchParams]
  );

  return updateQueryParams;
}
