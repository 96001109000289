import { globalSettings, tenantSettings } from "@src/Hooks/Dashboard/dashboards";

export function settingsRedirect(path: string, org: string) {
  const oldRoute = `/app/${org}/admin`;
  const tenantSettingsRoute = tenantSettings.route(org);
  const globalSettingsRoute = globalSettings.route(org);

  // TENANT SETTINGS RE-DIRECTIONS
  if (path.includes(`/map-settings`) || path.includes(`/completions`)) {
    return routesRedirect(path, tenantSettingsRoute);
  }

  if (path === `${oldRoute}/customizations` || path.includes(`/customizations/by-org`)) {
    return tenantSettingsRoute + "/customizations";
  }

  // GLOBAL SETTINGS RE-DIRECTIONS
  if (path.includes(`/customizations/env`)) {
    return globalSettingsRoute + "/customizations";
  }

  if (path.includes(`/users`) || path.includes(`/groups`) || path.includes("/organizations")) {
    return routesRedirect(path, globalSettingsRoute);
  }

  return tenantSettingsRoute;
}

function routesRedirect(path: string, initialRoute: string) {
  const matchPath = /^\/app\/([^\/]+\/){2}(?<path>.*)/;
  const match = path.match(matchPath);
  if (match?.groups?.path) {
    const path = match.groups.path;
    return `${initialRoute}/${path}`;
  } else {
    return initialRoute;
  }
}
