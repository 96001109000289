import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { PermCheckResponse } from "@src/Hooks/permissionsCheck";

type ProtectedRouteProps = {
  permissionCheck: PermCheckResponse;
  children?: ReactNode;
  redirectPath?: string;
};

export function ProtectedRoute({
  permissionCheck,
  redirectPath = "/",
  children
}: ProtectedRouteProps) {
  const { allowed } = permissionCheck;

  if (!allowed && allowed != null) {
    return <Navigate to={redirectPath} replace />;
  }
  return allowed == null ? <></> : <>{children}</>;
}

type ProtectedDashboard = {
  permissionCheck: PermCheckResponse;
  redirectPath?: string;
  children: ReactNode;
};

export function ProtectedDashboard({
  permissionCheck,
  children,
  redirectPath
}: ProtectedDashboard) {
  const orgCtx = useOrgCtx();
  const path = orgCtx ? `/app/${orgCtx}` : redirectPath;

  return (
    <ProtectedRoute permissionCheck={permissionCheck} redirectPath={path}>
      {children}
    </ProtectedRoute>
  );
}
