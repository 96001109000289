import { Navigate, useLocation } from "react-router-dom";

import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

import { settingsRedirect } from "./settingsRedirect";

// Redirect from old /admin route into their respective settings dashboard
export function SettingsRedirect() {
  const { pathname } = useLocation();
  const org = useOrgCtx() as string;

  const dest = settingsRedirect(pathname, org);
  return <Navigate to={dest} replace />;
}
