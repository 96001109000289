import PublisherIcon from "@img/cloud-arrow-up-solid.svg";
import ServiceDesignerIcon from "@img/project-diagram-solid.svg";
import ServicesIcon from "@img/services.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useDefaultOrg } from "@src/Hooks/defaultOrg";
import { useCanCreateBlockChart } from "@src/Hooks/Permissions/blockchart";

export function ServicesNavbar() {
  const orgCtx = useOrgCtx();
  const { org: defaultOrg } = useDefaultOrg();

  const { allowed: canCreateBlockChart } = useCanCreateBlockChart(orgCtx);

  const rootPath = `/app/${orgCtx || defaultOrg}/services/`;
  return (
    <>
      <NavIcons>
        <NavItem linkTo={rootPath + "overview"} label="Services" icon={ServicesIcon} />
        <NavItem
          linkTo={rootPath + "service-designer"}
          label="Designer"
          icon={ServiceDesignerIcon}
        />
        <NavItem
          disabled={!canCreateBlockChart}
          linkTo={rootPath + "publisher"}
          label="Publisher"
          icon={PublisherIcon}
        />
      </NavIcons>
    </>
  );
}
