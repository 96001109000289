import { useLocation } from "react-router-dom";

import {
  cloudManager,
  DashboardObject,
  globalSettings,
  infrastructureDashboard,
  landingPage,
  mpnDashboard,
  noDashboard,
  servicesDashboard,
  tenantSettings
} from "./dashboards";

export type Dashboard =
  | "services"
  | "mpn"
  | "mec"
  | "infrastructure"
  | "home"
  | "settings"
  | "global-settings"
  | "no-dashboard";

interface CurrentDashboardState {
  isLanding: boolean;
  isServices: boolean;
  isMPN: boolean;
  isMEC: boolean;
  isInfrastructure: boolean;
  isTenantSettings: boolean;
  isGlobalSettings: boolean;
  dashboard: DashboardObject;
}

export function useCurrentDashboard(): CurrentDashboardState {
  const { pathname } = useLocation();
  const route = pathname.split("/")[3] as Dashboard;

  const dashboardObject = getDashboardObject(route, pathname);

  return {
    isLanding: dashboardObject?.name === "home",
    isServices: dashboardObject?.name === "services",
    isMPN: dashboardObject?.name === "mpn",
    isMEC: dashboardObject?.name === "mec",
    isInfrastructure: dashboardObject?.name === "infrastructure",
    isTenantSettings: dashboardObject?.name === "settings",
    isGlobalSettings: dashboardObject?.name === "global-settings",
    dashboard: dashboardObject
  };
}

function getDashboardObject(dashboard: Dashboard, pathname: string): DashboardObject {
  switch (dashboard) {
    case "home":
      return landingPage;
    case "services":
      return servicesDashboard;
    case "mpn":
      return mpnDashboard;
    case "mec":
      return cloudManager;
    case "infrastructure":
      return infrastructureDashboard;
    case "settings":
      return tenantSettings;
    case "global-settings":
      return globalSettings;
    default:
      return pathname.split("/").includes("global-settings") ? globalSettings : noDashboard;
  }
}
